.model-header {
  > img {
    width: 70px;
    height: 70px;
    cursor: pointer;
    position: absolute;
    right: 5px;
    top: 7px;
  }
}
.model-body {
  &-title {
    font-weight: 500;
    font-size: 28px;
    color: #151515;
    line-height: 40px;
    text-shadow: 0px 2px 15px rgba(171,171,171,0.5);
  }
  &-desc {
    font-weight: 400;
    font-size: 16px;
    color: #151515;
    line-height: 22px;
    text-shadow: 0px 2px 15px rgba(171,171,171,0.5);
  }
  &-form {
    margin-top: 50px;
    .label-name {
      width: 125px;
      font-weight: 500;
      font-size: 18px;
      color: #151515;
      line-height: 25px;
      text-shadow: 0px 2px 15px rgba(171,171,171,0.5);
      text-align: left;
    }
    .form-btn {
      width: 300px;
      height: 50px;
      font-weight: 500;
      font-size: 18px;
      margin: 40px 0 0 10px;
      transition: box-shadow .45s ease-in-out, transform .45s ease-in-out;
    }
    .form-btn:hover {
      transform: translateY(-5px);
      box-shadow: 0 6px 16px fade(#000, 12);
    }
    .get-code {
      width: 150px;
      height: 40px;
      box-shadow: 0px 2px 15px 0px rgba(171,171,171,0.5);
      border-radius: 10px;
      border: 1px solid #C3012F;
      font-weight: 500;
      font-size: 16px;
      color: #C3012F;
      cursor: pointer;
      margin-left: 10px;
      flex-shrink: 0;
      transition: box-shadow .45s ease-in-out, transform .45s ease-in-out;
    }
    .get-code:hover {
      transform: translateY(-5px);
      box-shadow: 0 6px 16px fade(#000, 12);
    }
    .ant-form-item .ant-form-item-label >label::after {
      content: '' !important;
    }
  }
}