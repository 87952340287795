.warp-wealth {
  .title {
    font-weight: 600;
    font-size: 32px;
    color: #151515;
    border-left: 10px solid #C3012F;
    padding-left: 19px;
  }
  &-bg {
    width: 100%;
    min-height: 230px;
    background-image: url("../../../assets/images/product/study/league-bg.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    flex-direction: column;
    font-weight: 400;
    font-size: 32px;
    color: #151515;
    line-height: 45px;
    text-align: center;
  }
  &-bright {
    min-height: 965px;
    padding-top: 60px;
    .bright-col {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 350px;
      padding: 40px 0 0 24px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &-title {
        font-weight: 600;
        font-size: 40px;
        color: #FFFFFF;
        line-height: 56px;
      }
      &-desc {
        width: 306px;
        font-weight: 400;
        font-size: 16px;
        color: #FFFFFF;
        height: 100px;
        line-height: 21px;
      }
    }
    .bright-bg01 {
      background-image: url("../../../assets/images/product/wealth/bright01.png");
    }
    .bright-bg02 {
      background-image: url("../../../assets/images/product/wealth/bright02.png");
    }
    .bright-bg03 {
      background-image: url("../../../assets/images/product/wealth/bright03.png");
    }
    .bright-bg04 {
      background-image: url("../../../assets/images/product/wealth/bright04.png");
    }
    .bright-bg05 {
      background-image: url("../../../assets/images/product/wealth/bright05.png");
    }
    .bright-bg06 {
      background-image: url("../../../assets/images/product/wealth/bright06.png");
    }
  }
  &-expert {
    min-height: 815px;
    padding-top: 54px;
    background: #F8F8F8;
    .wrap-wealth-items {
      .wealth-item {
        width: 230px;
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          width: 130px;
          height: 130px;
        }
        &-circle {
          width: 142px;
          height: 142px;
          border: 6px solid rgba(195, 1, 47, 0.1);
          border-radius: 50%;
        }
        &-title {
          font-weight: 500;
          font-size: 24px;
          color: #151515;
          line-height: 33px;
          margin-top: 13px;
        }
        &-desc {
          font-weight: 400;
          font-size: 14px;
          color: #515151;
          line-height: 21px;
          margin-top: 10px;
          text-align: center;
        }
      }
    }
  }
  &-system {
    padding-top: 60px;
    min-height: 3520px;
    &-header {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      img {
        width: 90px;
        height: 90px;
        margin-right: 20px;
      }
      .system-header-title {
        font-weight: 600;
        font-size: 44px;
        color: #C3012F;
      }
    }
    &-grey {
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      background: linear-gradient( 90deg, #DDDDDD 0%, rgba(255,255,255,0) 100%);
      font-weight: 600;
      font-size: 20px;
      color: #151515;
      padding-left: 34px;
    }
    .wealth-server {
      &-title {
        font-weight: 400;
        font-size: 16px;
        color: #151515;
        line-height: 22px;
      }
      &-title::before {
        content: '';
        display: inline-block;
        width: 14px;
        height: 14px;
        background-color: #C3012F;
        border-radius: 50%;
        margin-right: 8px;
        margin-bottom: 2px;
        vertical-align: middle;
      }
      &-box {
        display: flex;
        align-items: center;
        &-left {
          width: 156px;
          height: 124px;
          background: #C3012F;
          border-radius: 10px;
          border: 2px solid #C30D23;
          font-weight: 500;
          font-size: 18px;
          line-height: 25px;
          color: #FFFFFF;
          flex-shrink: 0;
          text-align: center;
        }
        &-active {
          background: #FFFFFF;
          color: #C3012F;
        }
        &-line {
          width: 13px;
          height: 2px;
          background: #C3012F;
          margin: 0 8px;
        }
        &-right {
          width: 370px;
          height: 124px;
          background: #EFF4F5;
          border-radius: 10px;
          font-weight: 400;
          font-size: 14px;
          color: #515151;
          line-height: 21px;
          padding: 30px 27px 0 22px;
        }
      }
    }
  }
  &-member {
    height: 970px;
    padding-top: 54px;
    background: #F8F8F8;
    &-table {
      height: 773px;
      background: #FFFFFF;
      border-radius: 20px;
      padding: 53px 25px 0;
      .row-height {
        height: 65px;
        display: flex;
        align-items: center;
      }
      &-row {
        border-bottom: 1px dashed rgba(151, 151, 151, 0.5);
        //padding: 0 46px 0 67px;
        padding-left: 67px;
        .member-col-title {
          font-weight: 600;
          font-size: 18px;
          color: #151515;
        }
        .member-col-low {
          width: 160px;
          height: 38px;
          background: #E62956;
          border-radius: 19px;
          font-size: 18px;
          color: #FFFFFF;
        }
        .member-col-centre {
          width: 160px;
          height: 38px;
          background: #D21D48;
          border-radius: 19px;
          font-size: 18px;
          color: #FFFFFF;
        }
        .member-col-tall {
          width: 160px;
          height: 38px;
          background: #C3012F;
          border-radius: 19px;
          font-size: 18px;
          color: #FFFFFF;
        }
        .member-col-not {
          background: #CBCBCB;
        }
        .member-col-title-strong {
          font-weight: 600;
          font-size: 24px;
          color: #151515;
          line-height: 33px;
        }
        .member-col-title-red {
          font-size: 36px;
          color: #C3012F;
          line-height: 42px;
          font-weight: 600;
          span {
            font-size: 20px;
            color: #C3012F;
            line-height: 28px;
          }
        }
      }
    }
  }
}