.header {
  width: 100%;
  height: 70px;
  line-height: 70px;
  position: fixed;
  z-index: 99;
  top: 0;
  background-color: #ffffff;
  border-bottom: 1px solid rgba(151, 151, 151, 0.2);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px 0 20px;
  .logo {
    width: 180px;
    height: 42px;
    cursor: pointer;
    img {
      width: 100%;
    }
  }
  .header-right {
    display: flex;
    align-items: center;
    nav {
      height: 100%;
      border-bottom: 4px solid transparent;
      >ul {
        margin: 0;
        padding: 0;
        display: flex;
        list-style: none;
        height: 100%;
      }
      li {
        margin: 0 45px;
        cursor: pointer;
      }
      li a {
        text-decoration: none;
        font-weight: 500;
        font-size: 16px;
        color: #151515;
        display: block;
        height: 100%;
        &.active{
          position: relative;
          display: inline-block;
          border-bottom: 4px solid #CF182E;
        }
        &.active::after {
          content: "";
          position: absolute;
          left: 0;
          bottom: -4px;
          width: 50%;
          border-bottom: 4px solid #911936;
        }

      }
      li a:not(.active):hover {
        transition: 0.6s;
        color: #C3012F;
      }
    }
    .online-refer {
      width: 190px;
      height: 52px;
      line-height: 52px;
      display: flex;
      align-items: center;
      background: #C3012F;
      border-radius: 26px;
      color: #FFF;
      cursor: pointer;
      .refer {
        width: 61px;
        margin: 0 15px 0 18px;
        img {
          width: 100%;
          margin-top: 10px;
        }
      }
      span {
        font-weight: 500;
        font-size: 16px;
        color: #F1F1F1;
      }
    }
  }

}
@media screen and (max-width: 1300px) {
  .header {
    .header-right {
      nav {
        li {
          margin: 0 30px;
        }
      }
    }
  }
}