.wrap-case {
  min-height: 3400px;
  &-content {
    justify-content: space-between;
    padding: 70px 0;
  }
  .case-left{
    .title {
      font-weight: 600;
      font-size: 44px;
      color: #151515;
      line-height: 62px;
    }
    .desc {
      font-weight: 400;
      font-size: 22px;
      color: #151515;
      line-height: 36px;
      margin-top: 10px;
    }
    .banner1 {
      width: 820px;
      height: 438px;
      margin-top: 40px;
    }
    .banner02 {
      max-width: 796px;
      //height: 316px;
      margin: 57px 0 24px 30px;
    }
    .banner03 {
      width: 820px;
      margin-top: 10px;
    }
    .m-r86 {
      margin-top: 86px;
    }
    .target-block {
      min-height: 168px;
    }
    .project-block {
      min-height: 536px;
    }
    .assess-block {
      min-height: 1200px;
    }
    &-block {
      margin-top: 40px;
      .title-block {
        font-weight: 500;
        font-size: 24px;
        color: #151515;
        line-height: 36px;
      }
      .title-block::before {
        content: '';
        display: inline-block;
        width: 4px;
        height: 24px;
        background-color: #C3012F;
        margin-right: 26px;
        margin-bottom: 6px;
        vertical-align: middle;
      }
      .content {
        width: 790px;
        font-weight: 400;
        font-size: 16px;
        color: #151515;
        line-height: 36px;
        margin: 20px 0 0 31px;
      }
      .content-bg {
        width: 820px;
        min-height: 229px;
        background: #EFF4F5;
        border-radius: 10px;
        padding: 55px 50px 30px 30px;
        font-weight: 400;
        font-size: 16px;
        color: #151515;
        line-height: 36px;
        margin-top: 54px;
        position: relative;
        .img-bg {
          background-image: url("../../assets/images/case/dialog_tips.png");
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          width: 412px;
          height: 61px;
          position: absolute;
          top: -25px;
          left: 40px;
          font-weight: 600;
          font-size: 18px;
          color: #C3012F;
          padding-left: 26px;
          line-height: 49px;
        }
      }
      .content-block {
        width: 820px;
        min-height: 360px;
        border-radius: 10px;
        border: 4px solid #EFF4F5;
        margin-top: 28px;
        padding: 30px 30px 0;
        .target-title {
          width: fit-content;
          display: flex;
          height: 36px;
          background: #C3012F;
          border-radius: 10px;
          font-weight: 500;
          font-size: 16px;
          color: #FFFFFF;
          padding: 0 14px;
        }
        .target-desc {
          font-weight: 400;
          font-size: 16px;
          color: #151515;
          margin: 16px 0 30px 0px;
        }
      }
      .content-assess {
        width: 820px;
        background: #EFF4F5;
        border-radius: 10px;
        padding: 50px;
        margin-top: 24px;
        .m-t40 {
          margin-top: 40px;
        }
        .assess-title {
          font-weight: 600;
          font-size: 36px;
          color: #151515;
          line-height: 50px;
        }
        .assess-desc {
          font-weight: 400;
          font-size: 16px;
          color: #151515;
          line-height: 36px;
          &-block {
            display: flex;
            .dot {
              display: inline-block;
              width: 8px;
              height: 8px;
              background: #C3012F;
              margin-bottom: 2px;
              flex-shrink: 0;
              margin-top: 14px;
            }
            .text {
              margin-left: 26px;
            }
          }

        }
      }
    }
  }
  .case-right {
    padding-top: 10px;
    .title {
      font-weight: 600;
      font-size: 24px;
      color: #151515;
      line-height: 33px;
    }
    .line {
      width: 305px;
      height: 1px;
      border: 1px solid rgba(151, 151, 151, 0.2);
      margin: 26px 0 36px;
    }
    .desc-block {
      margin-bottom: 24px;
      display: flex;
      align-items: center;
      .circle {
        width: 6px;
        height: 6px;
        background: #BBBBBB;
        border-radius: 50%;
        margin-right: 14px;
      }
      .title-desc {
        font-weight: 400;
        font-size: 16px;
        color: #515151;
        line-height: 24px;
        cursor: pointer;
      }
      .title-desc:hover {
        font-weight: 600;
      }
      .title-desc-active {
        font-weight: 600;
        line-height: 24px;
        border-bottom: 1px solid #151515;
      }
      .circle-active {
        background: #C3012F;
      }
    }
  }
}