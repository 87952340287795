.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 800ms ease-in-out;
}
.fade-enter-active {
  opacity: 1;
}

.fade-exit {
  opacity: 0;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 800ms ease-in-out;
}
.fade-exit-done {
  opacity: 0;
}