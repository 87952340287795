.wrapper-study-tabs {

  .wrapper-title {
    height: 57px;
    width: 100%;
    background-color: #C3012F;
    position: fixed;
    z-index: 1;
  }
  .title-container {
    height: 57px;
    line-height: 57px;
    .s-title {
      margin-right: 50px;
      font-size: 16px;
      font-weight: 600;
      color: #FFF;
      cursor: pointer;
      position: relative;
    }
    .activeTitle {
      border-bottom: 3px solid #FFF;
      padding-bottom: 9px;
      transition: all 0.1s ease;
    }
    .title:not(.activeTitle):hover {
      font-weight: 800;
      transition: 0.2s;
    }
  }
  .content-container {
    padding-top: 57px;
  }
}