.wrap-list {
  padding: 40px 0;
  .direction {
    display: flex;
    margin-top: -29px;
    .m-r {
      margin-right: 20px;
    }
    .l-img {
      background-image: url("@/assets/images/active_left.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
    .r-img {
      background-image: url("@/assets/images/active_right.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
    .l-img:hover {
      background-image: url("@/assets/images/hover_left.png");
    }
    .r-img:hover {
      background-image: url("@/assets/images/hover_right.png");
    }
    .img-font {
      width: 64px;
      height: 64px;
      cursor: pointer;
    }
  }
  &-block {
    cursor: pointer;
    margin-bottom: 84px;
    > .list-date {
      font-weight: 600;
      font-size: 14px;
      color: #C3012F;
      line-height: 21px;
      margin-top: 20px;
      margin-right: 35px;
    }
    > .list-title {
      font-weight: 500;
      font-size: 24px;
      color: #151515;
      line-height: 33px;
      margin-top: 10px;
      margin-right: 35px;
    }
    > .list-desc {
      font-weight: 400;
      font-size: 14px;
      color: #515151;
      line-height: 21px;
      margin-top: 10px;
      margin-right: 35px;
    }
    > img {
      width: 390px;
      height: 300px;
      border-radius: 10px;
    }
    > img:hover {
      animation: shake 0.5s;
    }
  }
}