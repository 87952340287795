.sidebar-wrap {
  position: fixed;
  z-index: 99;
  right: 20px;
  bottom: 50px;
  height: 260px;
  //top: 50%;
  //transform: translateY(-50%);
  img {
    width: 44px;
    height: 44px;
    display: block;
    margin-bottom: 8px;
    cursor: pointer;
    transition: box-shadow @animate-duration ease-in-out, transform @animate-duration ease-in-out;
  }
  img:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 16px fade(#000, 12)
  }

  .icon-top {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.3s ease-out, transform 0.3s ease-out;
  }
  .icon-top:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 16px fade(#000, 12)
  }
  .fade-in {
    animation: topFadeIn 0.5s ease-out forwards;
  }

  .fade-out {
    animation: topFadeOut 0.5s ease-out forwards;
  }

  @keyframes topFadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes topFadeOut {
    from {
      opacity: 1;
      transform: translateY(0);
    }
    to {
      opacity: 0;
      transform: translateY(20px);
    }
  }
}

.popover_title {
  font-size: 14px;
  color: #515151;
  font-weight: 400;
}
.popover_content {
  font-weight: 600;
  font-size: 24px;
  color: #151515;
}

@media screen and (max-width: 1300px) {
  .sidebar-wrap {
    right: 10px;
  }
}