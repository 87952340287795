@line-color: #e9e9e9;
@shadow-color: rgba(0, 0, 0, 0.15);
@bottom-bar-bg-color: #262626;
@bottom-bar-line-color: #000;
@template-bg-color: #C30D23;
@template-bg-color-light: #ececec;
@template-nav-bg-color: #001529;
@template-text-color: #ccc;
@template-text-title-color: #bcbcbc;
@template-text-color-light: #fff;
@template-footer-text-color: #999;
@animate-duration: .45s;

#app {
  overflow-x: hidden;
}
.panel {
  opacity: 0;
}

.panel-enter {
  opacity: 0;
  transform: translateX(100%);
}

.panel-enter-active {
  opacity: 1;
  transform: translateX(0%);
  transition: opacity 400ms, transform 400ms;
}

.panel-enter-done {
  opacity: 1;
}

.panel-exit {
  opacity: 1;
  transform: translateX(0%);
}

.panel-exit-active {
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 400ms, transform 400ms;
}

.panel-exit-done {
  opacity: 0;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,body {
  height: 100%;
}

#app {
  font-family: PingFangSC-Regular, PingFang SC;
}

.header-top {
  margin-top: 70px;
}
.title-wrapper {
  margin: 0 auto 64px;
}
.home-page {
  max-width: 1200px;
  margin: auto;
  will-change: transform;
}
.s-loading-box {
  width: 100%;
  height: 100%;
  .loading-span {
    padding-top: 200px;
  }
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.p-pre-wrap {
  white-space: pre-wrap
}

.m-b18 {
  margin-bottom: 18px;
}
.m-t4 {
  margin-top: 4px;
}
.m-t8 {
  margin-top: 8px;
}
.m-t10 {
  margin-top: 10px;
}
.m-t11 {
  margin-top: 11px;
}
.m-t12 {
  margin-top: 12px;
}
.m-t13 {
  margin-top: 13px;
}
.m-t15 {
  margin-top: 15px;
}
.m-t20 {
  margin-top: 20px;
}
.m-t23 {
  margin-top: 23px;
}
.m-t28 {
  margin-top: 28px;
}
.m-t26 {
  margin-top: 26px !important;
}
.m-t30 {
  margin-top: 30px;
}
.m-t35 {
  margin-top: 35px;
}
.m-t42 {
  margin-top: 42px;
}
.m-t70 {
  margin-top: 70px;
}
.m-l15 {
  margin-left: 15px;
}
.m-l32 {
  margin-left: 32px;
}
.m-l55 {
  margin-left: 55px;
}
.m-b40 {
  margin-bottom: 40px;
}
.p-b32px {
  padding-bottom: 32px;
}