.warp-news {
  .title {
    font-weight: 500;
    font-size: 60px;
    color: #FFFFFF;
  }
  &-banner {
    background-image: url("../../../assets/images/about/news-banner.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 330px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-tabs {
    min-height: 800px;
    margin-top: 60px;
  }
}