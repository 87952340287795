.video-header {
  > img {
    width: 24px;
    height: 24px;
    cursor: pointer;
    position: absolute;
    right: 30px;
    top: 30px;
  }
}

.full-screen-modal {
  width: 75vw !important;
}

@media screen and (max-width: 1300px) {
  .full-screen-modal {
    width: 60vw !important;
  }
}