.warp-organize {
  background: #F8F8F8;
  &-bg {
    width: 100%;
    min-height: 230px;
    background: #FFFFFF;
    font-weight: 600;
    font-size: 32px;
    color: #151515;
    line-height: 45px;
    text-align: center;
  }
  &-content {
    padding-bottom: 120px;
    &-title {
      font-weight: 600;
      font-size: 32px;
      color: #151515;
      margin: 60px 0 20px;
    }
    &-block {
      height: 200px;
      background: #FFFFFF;
      border-radius: 10px;
      padding: 20px 20px 30px 30px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      transition: box-shadow @animate-duration ease-in-out, transform @animate-duration ease-in-out;
      .block-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        span {
          font-weight: 600;
          font-size: 14px;
          color: #909090;
        }
        img {
          width: 50px;
          height: 50px;
        }
      }
      .block-bottom {
        &-title {
          font-weight: 600;
          font-size: 20px;
          color: #151515;
          line-height: 28px;
          width: 283px;
        }
        &-desc {
          font-weight: 400;
          font-size: 16px;
          color: #515151;
          line-height: 22px;
          margin-top: 10px;
        }
      }
    }
    &-block:hover {
      transform: translateY(-5px);
      box-shadow: 0 6px 16px fade(#000, 12)
    }
  }
}