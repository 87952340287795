.wrapper-tabs {

  .title-container {
    border-bottom: 1px solid #979797;
    padding-bottom: 24px;
    .title {
      margin-right: 102px;
      font-weight: 600;
      font-size: 24px;
      color: #151515;
      cursor: pointer;
      position: relative;
    }
    .activeTitle {
      border-bottom: 7px solid #C3012F;
      padding-bottom: 18px;
      transition: all 0.1s ease;
    }
    .title:not(.activeTitle):hover {
      color: #C3012F;
      transition: 0.6s;
    }
  }
}