.warp-visit {
  background: #F8F8F8;
  &-content {
    padding: 65px 0;
    .title {
      text-align: center;
      font-weight: 600;
      font-size: 44px;
      color: #151515;
      line-height: 62px;
      margin-bottom: 24px;
    }
    .visit-bg {
      width: 100%
    }
    &-items {
      .content-item {
        background: #FFFFFF;
        border-radius: 10px;
        padding: 40px;
        margin-bottom: 30px;
        &-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 21px;
          .visit-header {
            display: flex;
            align-items: center;
            .visit-header-title {
              font-weight: 600;
              font-size: 40px;
              color: #151515;
              margin-right: 20px;
            }
            .visit-header-tag {
              width: 112px;
              height: 36px;
              background: #C3012F;
              border-radius: 10px;
              font-weight: 500;
              font-size: 16px;
              color: #FFFFFF;
            }
          }
          .visit-level {
            display: flex;
            align-items: center;
            img {
              width: 19px;
              height: 19px;
              margin-right: 6px;
            }
            span {
              font-weight: 600;
              font-size: 16px;
              color: #151515;
              margin-right: 20px;
            }
            span:last-child {
              margin-right: 0px;
            }
          }
        }
        &-tag {
          .tag-block {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            img {
              width: 18px;
              height: 18px;
              margin-right: 14px;
            }
            .dur {
              font-weight: 400;
              font-size: 16px;
              color: #151515;
              margin-right: 16px;
              flex-shrink: 0;
            }
            .day {
              font-weight: 600;
              font-size: 16px;
              color: #151515;
            }
          }
        }
        &-line {
          width: 100%;
          border-bottom: 1px solid rgba(151, 151, 151, 0.3);
          margin-top: 31px;
        }
        &-desc {
          font-weight: 400;
          font-size: 16px;
          color: #909090;
          line-height: 28px;
          margin-top: 20px;
        }
        &-block {
          margin-top: 30px;
          .b-title{
            font-weight: 600;
            font-size: 24px;
            color: #151515;
            line-height: 36px;
          }
          .b-title::before {
            content: '';
            display: inline-block;
            width: 4px;
            height: 24px;
            background-color: #C3012F;
            margin-right: 26px;
            margin-bottom: 6px;
            vertical-align: middle;
          }
          .b-content{
            font-weight: 400;
            font-size: 16px;
            color: #151515;
            line-height: 32px;
            margin: 10px 0 20px;
          }
          .b-content-weight{
            font-weight: 600;
            font-size: 16px;
            color: #151515;
            line-height: 32px;
            margin: 10px 0 20px;
          }
          .wrap-b-desc {
            margin: 10px 0 30px;
          }
          .b-desc {
            display: flex;
            align-items: center;
            line-height: 32px;
            font-weight: 400;
            font-size: 16px;
            color: #515151;
            .dot {
              display: inline-block;
              width: 8px;
              height: 8px;
              background: #C3012F;
              flex-shrink: 0;
            }
            .text {
              margin-left: 12px;
              span {
                color: #C3012F;
                font-weight: 600;
              }
            }
          }
          .b-wrap-pic {
            display: flex;
            margin-top: 14px;
            &-block {
              margin-right: 10px;
              img {
                width: 390px;
                height: 230px;
              }
              .pic-text {
                font-weight: 400;
                font-size: 16px;
                color: #515151;
                line-height: 32px;
                margin-top: 10px;
              }
            }
          }
          .b-release {
            margin-top: 34px;
            &-title {
              font-weight: 600;
              font-size: 32px;
              color: #C3012F;
              line-height: 45px;
            }
            &-hot {
              display: flex;
              margin-top: 30px;
              img {
                width: 320px;
                height: 189px;
              }
              &-text {
                font-weight: 400;
                font-size: 16px;
                color: #515151;
                line-height: 32px;
                margin: 8px 16px 0px 30px;
              }
            }
            .assume-bg {
              width: 100%;
              margin: 10px 0 20px;
            }
            &-blocks {
              margin-top: 14px;
              margin-bottom: 36px;
            }
            &-block {
              height: 60px;
              border-radius: 10px;
              border: 1px solid #C3012F;
              font-weight: 500;
              font-size: 16px;
              color: #C3012F;
            }
            &-box {
              background: #EFF4F5;
              border-radius: 20px;
              padding: 6px 40px 6px 6px;
              display: flex;
              margin-bottom: 10px;
              img {
                width: 240px;
                height: 128px;
              }
              .b-release-right {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                margin: 8px 0px 8px 22px;
              }
              &-name {
                font-weight: 600;
                font-size: 16px;
                color: #151515;
              }
              &-text {
                font-weight: 400;
                font-size: 16px;
                color: #515151;
              }
            }
          }
        }
      }
    }
    &-carousel {
      background: #F8F8F8;
      margin-top: 72px;
      .carousel-img {
        width: 100%;
        height: 650px;
        margin-bottom: 59px;
      }
      .case {
        width: 12px;
        height: 12px;
        border: 1px solid #C3012F;
        margin-right: 12px;
        cursor: pointer;
        &-active {
          background: #C3012F;
        }
      }
    }
  }
}