
.footer-wrapper {
  background: @template-bg-color;
  overflow: hidden;
  position: relative;
  color: @template-text-color-light;
  .footer {
    .home-page-footer {
      min-height: 284px;
      padding-top: 38px;
    }
  }
  .block {
    padding: 0 32px;
    .logo {
      width: 236px;
      height: 55px;
    }
    .slogan {
      font-size: 14px;
      margin-top: -20px;
    }
    >section {
      margin-bottom: 24px;
      color: @template-text-color-light;
      font-weight: 600;
      font-size: 16px;
      width: 130px;
    }
    a {
      color: @template-text-color-light;
      margin-bottom: 12px;
      float: left;
      clear: both;
      font-weight: 400;
      font-size: 15px;
      &:hover {
        font-weight: 600;
        text-decoration: underline;
      }
    }
    .qr-code {
      width: 106px;
      height: 106px;
    }
    img {
      width: 100%;
    }
  }
  .copyright-wrapper {
    width: 100%;
    border-top: 1px solid #A10215;
    height: 37px;
    line-height: 37px;
    .home-page-copyright {
      padding: 0 34px;
      overflow: hidden;
    }
    .copyright {
      text-align: left;
      font-size: 14px;
      a {
        color: #FFFFFF;
        text-decoration: none;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .footer {
    min-height: 550px;
    &-wrapper {
      .footer {
        .home-page {
          padding: 64px 24px 32px;
        }
      }
    }
    .logo {
      margin: 0 auto 24px;
    }
    .block {
      text-align: center;
      margin-bottom: 32px;
      padding: 0;
    }
    >ul {
      width: 90%;
      margin: 20px auto 0;
      padding: 10px 0;
      >li {
        width: 100%;
        h2 {
          margin-bottom: 10px;
        }
        li {
          display: inline-block;
          margin-right: 10px;
        }
      }
    }
    .copyright {
      &-wrapper {
        .home-page {
          padding: 0;
          .copyright {
            font-size: 12px;
          }
        }
      }
      span {
        width: 90%;
      }
    }
  }
}