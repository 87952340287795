.warp-join-detail {
  .title {
    font-size: 44px;
    font-weight: 600;
    color: #151515;
    margin-bottom: 28px;
  }
  .m-t80 {
    margin-top: 80px;
  }
  .m-t70 {
    margin-top: 70px;
  }
  &-work {
    padding: 80px 0 100px;
    min-height: 415px;
    .desc {
      font-size: 16px;
      color: #151515;
      line-height: 36px;

    }
    .message-block {
      width: 212px;
      border-top: 1px solid #979797;
      margin: 0 38px 21px 0;
      &-title {
        font-weight: 400;
        font-size: 12px;
        color: #999999;
        line-height: 20px;
        margin-top: 11px;
      }
      &-desc {
        font-weight: 600;
        font-size: 18px;
        color: #151515;
        line-height: 24px;
      }
    }
  }
  &-duty {
    min-height: 648px;
    background: #EFF4F5;
    border-radius: 20px;
    margin: 0 10px;
    .desc {
      font-weight: 400;
      font-size: 16px;
      color: #151515;
      line-height: 36px;
    }
  }
  &-contacts {
    height: 300px;
    background: #EFF4F5;
    border-radius: 20px;
    margin: 10px 10px 0;
    .email-text {
      font-weight: 600;
      font-size: 20px;
      color: #151515;
      line-height: 28px;
    }
    .email-btn {
      width: 218px;
      height: 48px;
      background: #C3012F;
      border-radius: 4px;
      border: 2px solid #C3012F;
      text-align: center;
      font-weight: 600;
      font-size: 16px;
      color: #FFFFFF;
      margin-top: 20px;
      cursor: pointer;
      transition: box-shadow @animate-duration ease-in-out, transform @animate-duration ease-in-out;
      img {
        width: 22px;
        height: 22px;
        margin-left: 12px;
      }
    }
    .email-btn:hover {
      transform: translateY(-5px);
      box-shadow: 0 6px 16px fade(#000, 12)
    }
  }
  .go-page {
    display: flex;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 23px;
    cursor: pointer;
    img {
      width: 24px;
      height: 24px;
    }
    span {
      font-weight: 600;
      font-size: 20px;
      color: #151515;
      margin-left: 8px;
    }
  }
}

@media screen and (max-width: 1300px) {
  .warp-join-detail {
    .title {
      font-size: 34px;
    }
  }
}