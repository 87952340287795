.wrap-theme {
  .title {
    font-weight: 600;
    font-size: 32px;
    color: #151515;
    border-left: 10px solid #C3012F;
    padding-left: 19px;
  }
  .desc {
    font-weight: 400;
    font-size: 16px;
    color: #151515;
    line-height: 28px;
  }
  &-custom {
    min-height: 803px;
    padding-top: 59px;
    .desc {
      margin: 10px 0 30px;
    }
    &-block {
      height: 561px;
      background: #C3012F;
      border-radius: 20px;
      display: flex;
      .block-left {
        padding: 124px 0 0 61px;
        .block-left-title {
          font-weight: 600;
          font-size: 40px;
          color: #FFFFFF;
          line-height: 44px;
          margin-bottom: 30px;
        }
        .block-left-desc {
          width: 237px;
          font-weight: 400;
          font-size: 16px;
          color: #FFFFFF;
          line-height: 28px;
        }
      }
      .block-right {
        display: flex;
        padding: 51px 0 0 63px;
        &-box {
          width: 190px;
          height: 460px;
          background: #FFFFFF;
          border-radius: 10px;
          margin-right: 15px;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-top: 30px;
          &-bg {
            width: 129px;
            height: 129px;
            background: rgba(195, 1, 47, 0.1);
            border-radius: 50%;
            position: relative;
            &-sub {
              width: 113px;
              height: 113px;
              line-height: 113px;
              background: #C3012F;
              border-radius: 50%;
              position: absolute;
              left: 0;
              right: 0;
              top: 0;
              bottom: 0;
              margin: auto;
              font-weight: 600;
              font-size: 40px;
              color: #FFFFFF;
              text-align: center;
            }
          }
          &-line {
            font-weight: 500;
            font-size: 24px;
            color: #C3012F;
            line-height: 28px;
            margin: 31px 0;
          }
          &-title {
            font-weight: 600;
            font-size: 24px;
            color: #151515;
            line-height: 28px;
            margin-bottom: 20px;
          }
          &-desc {
            font-weight: 400;
            font-size: 16px;
            color: #151515;
            line-height: 28px;
            text-align: center;
          }
        }
      }
    }
  }
  &-output {
    min-height: 662px;
    background: #F8F8F8;
    padding-top: 80px;
    .title {
      margin-bottom: 60px;
    }
  }
  &-leach {
    min-height: 992px;
    padding-top: 80px;
    .title {
      margin-bottom: 17px;
    }
  }
  &-vie {
    min-height: 1320px;
    background: #F8F8F8;
    padding-top: 70px;
    .title {
      margin-bottom: 31px;
      width: 1214px;
    }
    &-block {
      height: 552px;
      background: #FFFFFF;
      padding: 38px 30px 0;
      font-weight: 400;
      font-size: 16px;
      color: #151515;
      .vie-block-header {
        font-weight: 600;
        font-size: 18px;
        color: #C3012F;
        line-height: 25px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgba(151, 151, 151, 0.3);
        padding-bottom: 20px;
        &-big {
          font-size: 24px;
        }
        &-line {
          width: 3px;
          height: 26px;
          background-color: #C3012F;
          margin: 0 15px 0 21px;
        }
        &-desc {
          &-top {
            margin-left: -10px;
          }
          &-bottom {
            font-weight: 400;
            font-size: 14px;
            color: #C3012F;
          }
        }
      }
      .header-desc {
        font-weight: 400;
        font-size: 16px;
        color: #151515;
        line-height: 22px;
        align-items: flex-start !important;
        margin-top: 6px;
        .dot {
          margin-top: 6px;
        }
      }
      .vie-block-content {

        &-desc {
          display: flex;
          align-items: center;
          line-height: 22px;
          .dot {
            display: inline-block;
            width: 8px;
            height: 8px;
            background: #C3012F;
            flex-shrink: 0;
            margin-right: 8px;
          }
        }
        &-box {
          //height: 172px;
          background: #EFF4F5;
          text-align: center;
          padding: 18px 13px 13px;
          &-title {
            font-weight: 600;
          }
          &-desc {
            text-align: left;
          }
          &-sub {
            font-weight: 600;
            font-size: 18px;
            color: #C3012F;
            margin-top: 10px;
          }
        }
        &-plan {
          background: #EFF4F5;
          padding: 24px 0 24px 33px;
          font-size: 16px;
          span {
            color: #C3012F;
            margin-right: 8px;
          }
        }
        &-tips {
          font-weight: 600;
          font-size: 18px;
          color: #C3012F;
          line-height: 25px;
        }
      }
    }
  }
}

