.a-map {
  width: 672px;
  height: 508px;
  background: #D8D8D8;
  border-radius: 10px;
  border: 1px solid rgba(216, 216, 216, 0.5);
}

.amap-logo, .amap-copyright {
  display: none !important;
}