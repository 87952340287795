.warp-consult {
  .title {
    font-weight: 400;
    font-size: 44px;
    color: #151515;
    line-height: 62px;

    span {
      font-weight: 600;
    }
  }
  &-header {
    display: flex;
    background: #C3012F;
    img {
      width: 100%;
    }
  }
  &-manage {
    padding: 50px 0 74px;
    min-height: 1140px;
    background: #F8F8F8;
    .desc {
      font-weight: 400;
      font-size: 18px;
      color: #151515;
      line-height: 25px;
      margin-top: 8px;
    }
    .tactic-bg {
      text-align: center;
      margin: 74px 0 65px;
      img {
        width: 1100px;
        height: 288px;
      }
    }
    .warp-consult-blocks {
      .warp-consult-block {
        height: 239px;
        background: #FFFFFF;
        border-radius: 10px;
        padding: 26px 30px 24px;
        transition: box-shadow @animate-duration ease-in-out, transform @animate-duration ease-in-out;
        .consult-index {
          font-weight: 600;
          font-size: 28px;
          color: #C3012F;
          line-height: 40px;
        }
        .consult-title {
          font-weight: 600;
          font-size: 22px;
          color: #C3012F;
          line-height: 30px;
        }
        .consult-desc {
          font-weight: 400;
          font-size: 16px;
          color: #151515;
          line-height: 22px;
          margin-top: 31px;
          width: 248px;
        }
        .consult-tips {
          font-weight: 600;
          font-size: 16px;
          color: #151515;
          margin-top: 24px;
          display: flex;
          align-items: center;
          .dot {
            display: inline-block;
            width: 8px;
            height: 8px;
            background: #C3012F;
            flex-shrink: 0;
          }
        }
      }
      .warp-consult-block:hover {
        transform: translateY(-5px);
        box-shadow: 0 6px 16px fade(#000, 12)
      }
    }
  }
  &-finance {
    min-height: 842px;
    padding-top: 80px;
    .title {
      margin-bottom: 20px;
    }
  }
  &-talents {
    background: #F8F8F8;
    min-height: 625px;
    padding-top: 75px;
    .desc {
      font-weight: 400;
      font-size: 18px;
      color: #151515;
      line-height: 25px;
      margin: 8px 0 40px;
    }
    .talents-blocks {
      .talents-block {
        height: 320px;
        background: #FFFFFF;
        border-radius: 10px;
        padding: 68px 32px 0px;
        transition: box-shadow @animate-duration ease-in-out, transform @animate-duration ease-in-out;
        img {
          width: 50px;
          height: 50px;
        }
        &-title {
          font-weight: 600;
          font-size: 22px;
          color: #C3012F;
          line-height: 30px;
          margin: 4px 0 28px;
        }
        .talents-block-desc {
          font-weight: 500;
          font-size: 16px;
          color: #151515;
          line-height: 26px;
          display: flex;
          align-items: center;
          .dot {
            display: inline-block;
            width: 8px;
            height: 8px;
            background: #C3012F;
            flex-shrink: 0;
            margin-right: 9px;
          }
        }
      }
      .talents-block:hover {
        transform: translateY(-5px);
        box-shadow: 0 6px 16px fade(#000, 12)
      }
    }
  }
}