.warp-serve {
  padding-top: 63px;

  .title {
    font-weight: 400;
    font-size: 44px;
    color: #151515;
    line-height: 62px;

    span {
      font-weight: 600;
    }
  }

  &-header {
    text-align: center;
    border-bottom: 15px solid #C3012F;
    .title {
      margin-bottom: 56px;
    }
    img {
      width: 100%;
    }
  }

  &-scheme {
    min-height: 715px;
    padding: 60px 0;
    .title {
      margin-bottom: 10px;
    }
    .desc {
      font-weight: 500;
      font-size: 16px;
      color: #151515;
      line-height: 22px;
      margin-bottom: 40px;
    }
    .box {
      width: 1188px;
      height: 100px;
      background: #EFF4F5;
      border-radius: 4px;
      margin-left: 12px;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      &-tips {
        height: 56px;
        background: #FFFFFF;
        border: 2px solid #C30D23;
        font-weight: 600;
        font-size: 20px;
        color: #C3012F;
        border-left: 12px solid #C30D23;
        margin-left: -12px;
        padding: 0 46px 0 38px;
        flex-shrink: 0;
      }
      &-text {
        font-weight: 400;
        font-size: 16px;
        color: #151515;
        margin-left: 20px;
        margin-right: 40px;
      }
    }
    .box:last-child {
      margin-bottom: 0;
    }
  }
  &-foster {
    min-height: 545px;
    background: #F8F8F8;
    padding-top: 60px;
    .foster-blocks {
      margin-top: 40px;
    }
    .foster-block {
      height: 297px;
      background: #FFFFFF;
      border-radius: 10px;
      padding: 68px 32px 0px;
      img {
        width: 50px;
        height: 50px;
      }
      &-title {
        font-weight: 600;
        font-size: 22px;
        color: #C3012F;
        line-height: 30px;
        margin-top: 4px;
      }
      &-desc {
        font-weight: 500;
        font-size: 16px;
        color: #151515;
        line-height: 26px;
        margin-top: 30px;
      }
    }
  }
  &-message {
    min-height: 508px;
    padding-top: 60px;
    .message-blocks {
      margin-top: 49px;
    }
    .message-block {
      height: 238px;
      border-radius: 10px;
      border: 2px solid #C30D23;
      &-up {
        height: 131px;
        background: #C3012F;
        border-radius: 5px 5px 0px 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 30px;
        img {
          height: 90px;
        }
        .up-title {
          font-weight: 500;
          font-size: 24px;
          color: #FFFFFF;
          line-height: 33px;
        }
        .up-desc {
          font-weight: 400;
          font-size: 18px;
          color: #FFFFFF;
          line-height: 25px;
          margin-top: 2px;
        }
      }
      &-down {
        display: flex;
        flex-wrap: wrap;
        padding-top: 28px;
        line-height: 26px;
        .b-desc {
          display: flex;
          align-items: center;
          font-weight: 500;
          font-size: 16px;
          color: #515151;
          margin-left: 40px;
          .dot {
            display: inline-block;
            width: 8px;
            height: 8px;
            background: #C3012F;
            flex-shrink: 0;
          }
          .text {
            margin-left: 12px;
          }
        }
      }
    }
  }
}