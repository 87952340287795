.wrap-product-mark {
  .title {
    font-weight: 600;
    font-size: 44px;
    color: #151515;
  }
  .banner01 {
    position: relative;
    display: flex;
    .triangle-icon {
      width: 87px;
      height: 34px;
      position: absolute;
      left: 148px;
      bottom: -34px;
    }
  }
  .partner {
    &-top {
      height: 149px;
      justify-content: space-between;
      margin-top: 70px;
      &-host {
        width: 457px;
        background: #EFF4F5;
        border-radius: 10px;
        display: flex;
        align-items: center;
        > img {
          width: 197px;
          height: 46px;
        }
        .host-title {
          margin: 0 50px 0 38px;
        }
      }
      &-rest {
        width: 725px;
        background: #EFF4F5;
        border-radius: 10px;
        display: flex;
        align-items: center;
        .rest-title {
          margin: 0 41px 0 52px;
        }
        .stock01 {
          width: 240px;
          height: 80px;
        }
        .stock02 {
          width: 200px;
          height: 80px;
        }
      }
    }
    &-bottom {
      height: 218px;
      background: #EFF4F5;
      border-radius: 10px;
      margin: 20px 0 70px;
      display: flex;
      align-items: center;
      &-title {
        margin: 0 79px 0 38px;
      }
      &-block {
        display: flex;
        align-items: center;
        img {
          width: 195px;
          height: 69px;
        }
      }
    }
    .partner-line {
      width: 2px;
      background: #D8D8D8;
      height: 40px;
      margin: 0 14px;
    }
    .partner-text {
      font-weight: 600;
      font-size: 24px;
      color: #151515;
    }
  }
  .warp-challenge {
    min-height: 462px;
    &-items {
      //display: flex;
      //justify-content: space-between;
      margin-top: 64px;
      .challenge-item {
        text-align: center;
        margin-right: 50px;
        //width: 20%;
        .title-img {
          font-weight: 500;
          font-size: 24px;
          color: #151515;
          margin-top: 23px;
          text-align: center;
        }
        img {
          width: 200px;
          height: 200px;
        }
      }
      .challenge-item:last-child {
        margin-right: 0;
      }
    }
  }
  .wrap-merit {
    padding: 60px 0 172px;
    position: relative;
    &-block {
      display: flex;
      justify-content: space-between;
      .project-img {
        width: 540px;
        height: 346px;
      }
      .desc {
        font-weight: 400;
        font-size: 16px;
        color: #151515;
        line-height: 36px;
      }
    }
    .m-b-title {
      margin-bottom: 28px;
    }
    .m-t01 {
      margin-top: 114px;
    }
    .circle01 {
      height: 540px;
      position: absolute;
      z-index: -1;
      right: 0px;
      top: 140px;
    }
    .circle02 {
      height: 540px;
      position: absolute;
      z-index: -1;
      left: 0px;
      top: 592px;
    }
    .circle03 {
      height: 540px;
      position: absolute;
      z-index: -1;
      right: 0px;
      bottom: 76px;
    }
  }
  .wrap-drill-col {
    min-height: 1254px;
    padding-bottom: 90px;
    .desc {
      font-weight: 400;
      font-size: 16px;
      color: #151515;
      line-height: 36px;
      margin-top: 24px;
      margin-bottom: 64px;
    }
    &-items {
      .col-item {
        width: 595px;
        height: 512px;
        background: #EFF4F5;
        border-radius: 20px;
        padding: 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .item-title {
          font-weight: 600;
          font-size: 32px;
          color: #151515;
          line-height: 44px;
        }
        .item-desc {
          font-weight: 400;
          font-size: 16px;
          color: #151515;
          line-height: 36px;
        }
      }
    }
  }
  .wrap-drill-row {
    min-height: 1254px;
    padding-bottom: 90px;
    .desc {
      font-weight: 400;
      font-size: 16px;
      color: #151515;
      line-height: 36px;
      margin-top: 24px;
      margin-bottom: 64px;
    }
    &-items {
      .row-item {
        background: #EFF4F5;
        border-radius: 20px;
        padding: 30px !important;
        display: flex;
        justify-content: space-between;
        .item-title {
          font-weight: 600;
          font-size: 32px;
          color: #151515;
          line-height: 44px;
        }
        .item-desc {
          font-weight: 400;
          font-size: 16px;
          color: #151515;
          line-height: 36px;
          margin-top: 32px;
        }
        .row-item-right {
          text-align: center;
          img {
            width: 298px;
            height: 236px;
          }
          &-text {
            font-weight: 400;
            font-size: 16px;
            color: #151515;
            margin-top: 6px;
          }
        }
      }
    }
  }
  .wrap-tutor {
    min-height: 728px;
    &-items {
      .tutor-item {
        width: 368px;
        margin-top: 40px;
        img {
          width: 130px;
          height: 130px;
        }
        .item-title {
          font-weight: 500;
          font-size: 24px;
          color: #151515;
          line-height: 33px;
          margin-top: 19px;
        }
        .item-desc {
          font-weight: 400;
          font-size: 14px;
          color: #515151;
          line-height: 21px;
          margin-top: 10px;
        }
      }
    }
  }
  .wrap-drill-tutor {
    width: 100%;
    height: 320px;
    background: #EFF4F5;
    display: flex;
    align-items: center;
    .desc {
      font-weight: 400;
      font-size: 20px;
      color: #151515;
      line-height: 28px;
      margin-top: 14px;
    }
    .m-r {
      margin-top: 28px;
    }
    .drill-tutor-right {
      &-block {
        width: 350px;
        height: 110px;
        background: #FFFFFF;
        border-radius: 10px;
        display: flex;
        align-items: center;
        padding-right: 20px;
        //margin: 0 20px 20px 0;
        img {
          width: 46px;
          height: 46px;
          margin: 0 15px 0 20px;
        }
        .title-right {
          font-weight: 500;
          font-size: 24px;
          color: #151515;
          line-height: 33px;
        }
        .desc-right {
          font-weight: 400;
          font-size: 14px;
          color: #515151;
          line-height: 21px;
        }
      }
    }
  }
  .wrap-invite {
    min-height: 391px;
    display: flex;
    align-items: center;
    .desc {
      font-weight: 400;
      font-size: 16px;
      color: #151515;
      line-height: 36px;
      margin-top: 41px;
    }
    .m-t {
      margin-top: 16px;
    }
    .wrap-invite-content {
      width: 100%;
      justify-content: space-between;
    }
    &-code {
      text-align: center;
      img {
        width: 204px;
        height: 204px;
      }
      &-text {
        font-weight: 600;
        font-size: 18px;
        color: #C30D23;
        line-height: 36px;
        margin-top: 4px;
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .wrap-product-mark {
    font-size: 34px;
  }
}