.warp-join {
  .title {
    font-size: 44px;
    font-weight: 600;
    color: #151515;
    margin-bottom: 28px;
  }
  &-banner {
    padding: 8px 10px 0;
    img {
      width: 100%;
      transition: transform 0.5s ease;
    }
    img:hover {
      transform: scale(1.01);
    }
  }
  &-work {
    padding: 80px 0 100px;
    min-height: 415px;
    .desc {
      font-size: 16px;
      color: #151515;
      line-height: 36px;

    }
    .message-block {
      width: 212px;
      border-top: 1px solid #979797;
      margin: 0 38px 21px 0;
      &-title {
        font-weight: 400;
        font-size: 12px;
        color: #999999;
        line-height: 20px;
        margin-top: 11px;
      }
      &-desc {
        font-weight: 600;
        font-size: 18px;
        color: #151515;
        line-height: 24px;
      }
    }
  }
  &-detail {
    min-height: 923px;
    padding-bottom: 27px;
    .join-desc {
      font-weight: 400;
      font-size: 18px;
      color: #151515;
      line-height: 36px;
      margin: 28px 0 38px;
    }
    .wrap-join-block {
      margin-bottom: 40px;
      .join-img {
        width: 385px;
        height: 260px;
        cursor: pointer;
      }
      .join-img:hover {
        animation: shake 0.5s;
      }
      .join-title {
        font-weight: 600;
        font-size: 18px;
        color: #151515;
        line-height: 25px;
        margin-top: 20px;
        display: flex;
        align-items: center;
        cursor: pointer;
        .arrows-img {
          width: 24px;
          height: 24px;
          margin-left: 8px;
        }
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .warp-join {
    .title {
      font-size: 34px;
    }
  }
}
@keyframes shake {
  0% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  50% { transform: translateX(5px); }
  75% { transform: translateX(-3px); }
  100% { transform: translateX(0); }
}