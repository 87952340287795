.wrap-study {
  .title {
    font-weight: 600;
    font-size: 40px;
    color: #151515;
  }
  .desc {
    font-weight: 400;
    font-size: 16px;
    color: #151515;
  }
  .warp-study-list {
    min-height: 1412px;
    .warp-study-list-top {
      padding-top: 90px;
      justify-content: space-between;
      .title {
        line-height: 62px;
      }
      .desc {

        line-height: 36px;
        margin-top: 10px;
      }
      .history-bg {
        width: 440px;
        height: 304px;
        margin-bottom: -10px;
      }
    }
    .warp-study-list-bottom {
      background: #F8F8F8;
      padding-top: 60px;
      padding-bottom: 72px;
      .warp-study-list-block {
        margin-bottom: 62px;
        > .list-name {
          font-weight: 600;
          font-size: 14px;
          color: #C3012F;
          line-height: 21px;
          margin-top: 20px;
          margin-right: 35px;
        }
        > .list-title {
          font-weight: 500;
          font-size: 24px;
          color: #151515;
          line-height: 33px;
          margin-top: 10px;
          margin-right: 35px;
        }
        > .list-desc {
          font-weight: 400;
          font-size: 14px;
          color: #515151;
          line-height: 21px;
          margin-top: 10px;
          margin-right: 35px;
        }
        > img {
          width: 385px;
          height: 240px;
        }
        > img:hover {
          animation: shake 0.5s;
        }
      }
    }
  }
  .warp-theme-list {
    background: #F8F8F8;
    padding-top: 30px;
    padding-bottom: 30px;
    &-block {
      background: #FFFFFF;
      border-radius: 10px;
      margin-bottom: 30px;
      padding: 35px 30px 26px 40px;
      .warp-theme-content {
        width: 100%;
      }
      &-left {
        border-bottom: 1px solid rgba(151, 151, 151, 0.3);
        padding-bottom: 20px;
        .title {
          line-height: 56px;
        }
        .level {
          display: flex;
          align-items: center;
          margin: 6px 0 27px;
          img {
            width: 19px;
            height: 19px;
            margin-right: 6px;
          }
          span {
            font-weight: 600;
            font-size: 16px;
            color: #151515;
            margin-right: 20px;
          }
        }
        .tag {
          &-block {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            img {
              width: 18px;
              height: 18px;
              margin-right: 14px;
            }
            .dur {
              font-weight: 400;
              font-size: 16px;
              color: #151515;
              margin-right: 16px;
            }
            .day {
              font-weight: 600;
              font-size: 16px;
              color: #151515;
            }
          }
        }
        .desc {
          font-weight: 400;
          font-size: 16px;
          color: #909090;
          line-height: 28px;
          margin-top: 20px;
        }
      }
      .theme-bg {
        width: 427px;
        height: 262px;
        border-radius: 10px;
      }
      .warp-theme-bottom {
        .b-title{
          font-weight: 500;
          font-size: 24px;
          color: #151515;
          line-height: 36px;
        }
        .b-title::before {
          content: '';
          display: inline-block;
          width: 4px;
          height: 24px;
          background-color: #C3012F;
          margin-right: 26px;
          margin-bottom: 6px;
          vertical-align: middle;
        }
        .b-content{
          font-weight: 400;
          font-size: 16px;
          color: #151515;
          line-height: 32px;
          margin: 10px 0 20px;
        }
        .wrap-b-desc {
          margin: 10px 0 30px;
        }
        .b-desc {
          display: flex;
          align-items: baseline;
          line-height: 32px;
          font-weight: 400;
          font-size: 16px;
          color: #515151;
          .dot {
            display: inline-block;
            width: 8px;
            height: 8px;
            background: #C3012F;
            flex-shrink: 0;
          }
          .text {
            margin-left: 12px;
            span {
              color: #C3012F;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
  .warp-league-list {
    &-bg {
      width: 100%;
      min-height: 230px;
      background-image: url("../../../assets/images/product/study/league-bg.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      flex-direction: column;
      font-weight: 400;
      font-size: 32px;
      color: #151515;
      line-height: 45px;
      .l-strong {
        font-weight: 600;
      }
      .l-color {
        font-weight: 600;
        color: #C3012F;
      }
    }
    &-content {
      background: #F8F8F8;
      padding: 65px 0;
      .wrap-header {
        display: flex;
        margin-bottom: 20px;
        .desc {
          font-weight: 400;
          font-size: 24px;
          color: #C3012F;
          line-height: 33px;
        }
        .title {
          font-weight: 600;
          font-size: 44px;
          color: #C3012F;
          line-height: 44px;
          margin-top: 4px;
        }
        img {
          width: 90px;
          height: 90px;
          margin-right: 20px;
        }
      }
      .wrap-header:not(:first-child) {
        margin-top: 87px;
      }
      .wrap-content {
        display: flex;
        background: #FFFFFF;
        border-radius: 10px;
        padding: 20px 32px 20px 20px;
        margin-bottom: 30px;
        &-block {
          img {
            width: 356px;
            height: 356px;
            border-radius: 10px;
          }
          &-right {
            .content-title {
              font-weight: 600;
              font-size: 40px;
              color: #151515;
              line-height: 56px;
              margin: 10px 0 20px;
            }
            .content-desc {
              &-t {
                font-weight: 500;
                font-size: 24px;
                color: #151515;
                line-height: 36px;
                margin-bottom: 10px;
              }
              &-t::before {
                content: '';
                display: inline-block;
                width: 4px;
                height: 24px;
                background-color: #C3012F;
                margin-right: 26px;
                margin-bottom: 6px;
                vertical-align: middle;
              }
              &-c {
                font-weight: 400;
                font-size: 16px;
                color: #515151;
                line-height: 32px;
                margin-bottom: 20px;
              }
            }
          }
        }
      }
    }
  }
}