.wrap-enterprise {
  .title {
    font-weight: 600;
    font-size: 44px;
    color: #151515;
  }
  .desc {
    font-weight: 400;
    font-size: 16px;
    color: #151515;
  }
  .enterprise-page {
    max-width: 1000px;
    margin: auto;
  }
  .enterprise-page02 {
    max-width: 1100px;
    margin: auto;
  }
  &-header {
    display: flex;
    background: #C3012F;
    img {
      width: 100%;
    }
  }
  &-branding {
    padding: 98px 0 52px;
    justify-content: space-between;
    img {
      width: 240px;
      height: 240px;
    }
    .title {
      line-height: 62px;
    }
    .desc {
      line-height: 36px;
      margin-top: 10px;
    }
  }
  &-box {
    background: #EFF4F5;
    border-radius: 20px;
    padding: 30px 0 45px;
    margin: 0 10px 10px;
    .box-title {
      font-weight: 600;
      font-size: 32px;
      color: #151515;
      line-height: 62px;
    }
    .box-desc {
      font-weight: 400;
      font-size: 16px;
      color: #151515;
      line-height: 36px;
      margin-bottom: 10px;
    }
    .img-bg {
      margin-top: 16px;
    }
    .box-btn {
      width: max-content;
      background: #C3012F;
      border-radius: 3px;
      border: 2px solid #C3012F;
      height: 37px;
      line-height: 37px;
      padding: 0 16px 0 13px;
      margin-bottom: 8px;
      &-text {
        font-size: 13px;
        color: #FFFFFF;
        margin-right: 8px;
      }
      img {
        width: 17px;
        height: 16px;
      }
    }
    .block-summary {
      width: 350px;
      height: 79px;
      background: #FFFFFF;
      border: 1px solid #C3012F;
      display: flex;
      align-items: center;
      &-title {
        font-weight: 600;
        font-size: 18px;
        color: #151515;
        margin-left: 40px;
      }
      &-line {
        width: 1px;
        height: 42px;
        background: rgba(84, 84, 84, 0.49);
        margin: 0 40px;
      }
      &-text {
        font-weight: 400;
        font-size: 16px;
        color: #151515;
        line-height: 22px;
      }
    }
    &-row {
      justify-content: space-between;
      margin-bottom: 10px;
    }
  }
  &-feature {
    padding: 42px 0;
    margin: 0 10px;
  }
  &-system {
    min-height: 1550px;
    padding-top: 80px;
    .title {
      margin-bottom: 62px;
    }
    .enterprise-block-desc {
      font-weight: 500;
      font-size: 16px;
      color: #151515;
      line-height: 26px;
      display: flex;
      align-items: center;
      .dot {
        display: inline-block;
        width: 8px;
        height: 8px;
        background: #C3012F;
        flex-shrink: 0;
        margin-right: 9px;
      }
    }
    .enterprise-blocks {
      .enterprise-block {
        height: 330px;
        background: #EFF4F5;
        border-radius: 10px;
        padding: 30px 30px 0px;
        img {
          width: 31px;
          height: 34px;
        }
        &-title {
          font-weight: 600;
          font-size: 22px;
          color: #C3012F;
          line-height: 30px;
          margin: 18px 0 30px;
        }
      }
      .enterprise-double {
        background: #F8F8F8;
      }
    }
    .enterprise-blocks02 {
      height: 202px;
      background: #EFF4F5;
      border-radius: 10px;
      padding: 30px;
      &-header {
        display: flex;
        align-items: flex-end;
        margin-bottom: 30px;
        img {
          width: 31px;
          height: 34px;
          margin-right: 16px;
        }
        &-title {
          font-weight: 600;
          font-size: 22px;
          color: #C3012F;
        }
      }
    }
    .enterprise-box-left {
      min-height: 694px;
      background: #F8F8F8;
      padding: 30px 43px 30px 30px;
      border-radius: 10px;
      .left-title {
        font-weight: 600;
        font-size: 18px;
        color: #151515;
        line-height: 26px;
        margin-bottom: 10px;
      }
      .left-line {
        height: 1px;
        width: 100%;
        background: rgba(151, 151, 151, 0.3);
        margin: 31px 0 29px;
      }
    }
    .enterprise-box-right {
      &-one {
        background: #F8F8F8;
        border-radius: 10px;
        height: 227px;
        margin-bottom: 14px;
        padding: 30px;
      }
      &-two {
        background: #EFF4F5;
        border-radius: 10px;
        height: 202px;
        margin-bottom: 16px;
        padding: 30px;
      }
      &-three {
        background: #F8F8F8;
        border-radius: 10px;
        height: 235px;
        padding: 30px;
      }
    }
    .enterprise-box-header {
      display: flex;
      align-items: flex-end;
      margin-bottom: 30px;
      img {
        width: 31px;
        height: 34px;
        margin-right: 16px;
      }
      .enterprise-box-header-title {
        font-weight: 600;
        font-size: 22px;
        color: #C3012F;
      }
    }
  }
}