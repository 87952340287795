@font-face {
  font-family: 'DIN';
  src: url('@/assets/fonts/d-din-exp.ttf') format('truetype'); /* chrome、firefox、opera、Safari, Android, iOS 4.2+ */
  font-style: normal;
  font-weight: normal;
}
@import '@/common.less';
@import '@/components/Header/index.less';
@import '@/components/Footer/index.less';
@import '@/components/Sidebar/index.less';
@import '@/components/NavbarModel/index.less';
@import '@/pages/Home/index.less';
@import '@/pages/Product/index.less';
@import '@/pages/Product/OneMark/index.less';
@import '@/pages/Product/Study/index.less';
@import '@/pages/Product/Visit/index.less';
@import '@/pages/Product/Tactic/index.less';
@import '@/pages/Product/Wealth/index.less';
@import '@/pages/Product/Organize/index.less';
@import '@/pages/Product/Enterprise/index.less';
@import '@/pages/Product/Consult/index.less';
@import '@/pages/Scheme/index.less';
@import '@/pages/Scheme/Theme/index.less';
@import '@/pages/Case/index.less';
@import '@/pages/Serve/index.less';
@import '@/pages/About/index.less';
@import '@/pages/About/Join/index.less';
@import '@/pages/About/JoinDetail/index.less';
@import '@/pages/About/News/index.less';
@import '@/pages/About/NewsDetail/index.less';
@import '@/pages/About/Contact/index.less';
