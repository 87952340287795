.warp-detail-news {
  padding: 40px 0 110px;
  .detail-news-date {
    font-weight: 600;
    font-size: 14px;
    color: #C3012F;
    margin-bottom: 20px;
  }
  .detail-news-title {
    font-weight: 600;
    font-size: 44px;
    color: #151515;
    line-height: 62px;
  }
  .detail-news-banner {
    height: 400px;
    margin: 40px 0;
    border-radius: 10px;
  }
  .detail-news-block {
    max-width: 1000px;
    margin: auto;
    .detail-news-desc {
      font-weight: 400;
      font-size: 16px;
      color: #151515;
      line-height: 36px;
      .sub-title {
        font-weight: 600;
        color: #C3012F;
      }
    }
    .detail-news-video {
      height: 500px;
      margin: 76px 0 40px;
      position: relative;
      .detail-news-mask {
        position: absolute;
        background: rgba(0, 0, 0, 0.4);
        border-radius: 10px;
        z-index: 1;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        img {
          width: 60px;
          height: 60px;
          cursor: pointer;
        }
      }
    }
  }
  .go-page {
    max-width: 1000px;
    display: flex;
    align-items: center;
    margin: 94px auto 0;
    cursor: pointer;
    img {
      width: 24px;
      height: 24px;
    }
    span {
      font-weight: 600;
      font-size: 20px;
      color: #151515;
      margin-left: 8px;
    }
  }
}