.__dropdown-nav {
  border-top: 4px solid #C3012F;
  margin-top: -9px;
  opacity: 0;
  padding: 34px 74px 60px 47px;
  background: #EFF4F5;
  box-shadow: 0px 2px 15px 0px rgba(171,171,171,0.5);
  animation: fadeIn 0.8s ease forwards;
  .nav-bar {
    text-shadow: 0px 2px 15px rgba(171,171,171,0.5);
    > .nav-col {
      padding-left: 47px;
    }
    .nav-title {
      font-weight: 500;
      font-size: 23px;
      color: #151515;
      line-height: 32px;
      cursor: pointer;
    }
    .nav-title:hover {
      transition: 0.6s;
      color: #C3012F;
    }
    .nav-desc {
      font-weight: 400;
      font-size: 13px;
      color: #515151;
      width: 180px;
    }
    .nav-list {
      margin-top: 27px;
      &-block {
        font-weight: 500;
        font-size: 19px;
        color: #151515;
        margin-top: 21px;
        cursor: pointer;
      }
      &-block:hover {
        transition: 0.6s;
        color: #C3012F;
      }
    }
    .active {
      color: #C3012F;
    }
  }
}
.dropdown_pd {
  padding: 0 36px 40px;
}
&.__dropdown-nav::after {
  content: "";
  position: absolute;
  left: 0;
  top: -9px;
  width: 32px;
  border-top: 4px solid #911936;
}