.warp-tactic {
  &-bg {
    width: 100%;
    min-height: 230px;
    background-image: url("../../../assets/images/product/study/league-bg.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    flex-direction: column;
    font-weight: 400;
    font-size: 32px;
    color: #151515;
    line-height: 45px;
    text-align: center;
    .l-color {
      font-weight: 600;
      color: #C3012F;
    }
  }
  &-content {
    background: #F8F8F8;
    padding: 78px 0;
    &-header {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      img {
        width: 90px;
        height: 90px;
        margin-right: 20px;
      }
      .title {
        font-weight: 600;
        font-size: 44px;
        color: #C3012F;
      }
    }
    &-items {
      .content-item {
        background: #FFFFFF;
        border-radius: 10px;
        padding: 40px;
        margin-bottom: 30px;
        &-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 21px;
          .tactic-header {
            display: flex;
            align-items: center;
            &-title {
              font-weight: 600;
              font-size: 40px;
              color: #151515;
              margin-right: 14px;
            }
            &-tag {
              width: 112px;
              height: 36px;
              background: #C3012F;
              border-radius: 10px;
              font-weight: 500;
              font-size: 16px;
              color: #FFFFFF;
              margin-left: 6px;
            }
          }
          .tactic-level {
            display: flex;
            align-items: center;
            img {
              width: 19px;
              height: 19px;
              margin-right: 6px;
            }
            span {
              font-weight: 600;
              font-size: 16px;
              color: #151515;
              margin-right: 20px;
            }
            span:last-child {
              margin-right: 0px;
            }
          }
        }
        &-tag {
          .tag-block {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            img {
              width: 18px;
              height: 18px;
              margin-right: 14px;
            }
            .dur {
              font-weight: 400;
              font-size: 16px;
              color: #151515;
              margin-right: 16px;
              flex-shrink: 0;
            }
            .day {
              font-weight: 600;
              font-size: 16px;
              color: #151515;
            }
          }
        }
        &-line {
          width: 100%;
          border-bottom: 1px solid rgba(151, 151, 151, 0.3);
          margin-top: 31px;
        }
        &-block {
          margin-top: 30px;
          .b-title{
            font-weight: 600;
            font-size: 24px;
            color: #151515;
            line-height: 36px;
          }
          .b-title::before {
            content: '';
            display: inline-block;
            width: 4px;
            height: 24px;
            background-color: #C3012F;
            margin-right: 26px;
            margin-bottom: 6px;
            vertical-align: middle;
          }
          .b-content{
            font-weight: 400;
            font-size: 16px;
            color: #151515;
            line-height: 32px;
            margin: 10px 0 20px;
          }
          .wrap-b-desc {
            margin: 10px 0 30px;
          }
          .b-desc {
            display: flex;
            align-items: center;
            line-height: 32px;
            font-weight: 400;
            font-size: 16px;
            color: #515151;
            .dot {
              display: inline-block;
              width: 8px;
              height: 8px;
              background: #C3012F;
              flex-shrink: 0;
            }
            .text {
              margin-left: 12px;
              span {
                color: #C3012F;
                font-weight: 600;
              }
            }
          }
        }
      }
    }
  }
}