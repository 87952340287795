.warp-contact {
  padding: 60px 0;
  .title {
    width: 766px;
    font-weight: 600;
    font-size: 44px;
    color: #151515;
    line-height: 62px;
    text-align: center;
    margin-bottom: 64px;
  }
  .banner-img {
    width: 100%;
    padding: 10px;
  }
  .wrap-place {
    margin: 10px;
    height: 630px;
    background: #EFF4F5;
    border-radius: 20px;
    padding-top: 60px;
    &-block {
      display: flex;
      justify-content: space-between;
    }
    .place-title {
      font-weight: 600;
      font-size: 44px;
      color: #151515;
    }
    .place-desc {
      margin-top: 28px;
      font-weight: 400;
      font-size: 16px;
      color: #151515;
      line-height: 36px;
    }
  }
}

@media screen and (max-width: 1300px) {
  .warp-contact {
    .title {
      font-size: 34px;
      width: 608px;
    }
    .wrap-place {
      .place-title {
        font-size: 34px;
      }
    }
  }
}