.warp-about {
  .title {
    font-size: 44px;
    font-weight: 600;
    color: #151515;
  }
  &-banner {
    margin-top: 8px;
    padding: 8px 10px 0;
    img {
      width: 100%;
      display: flex;
      transition: transform 0.5s ease;
    }
    img:hover {
      transform: scale(1.01);
    }
    .detail-news-video {
      position: relative;
      .detail-news-mask {
        position: absolute;
        background: rgba(0, 0, 0, 0.4);
        border-radius: 26px;
        z-index: 1;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        img {
          width: 60px;
          height: 60px;
          cursor: pointer;
        }
        img:hover {
          animation: shake 0.5s;
        }
      }
    }
  }
  &-investor {
    display: flex;
    justify-content: space-between;
    padding: 80px 0 88px;
    &-left {
      .title {
        margin-bottom: 35px;
      }
      .desc {
        font-weight: 400;
        font-size: 16px;
        color: #151515;
        line-height: 36px;
      }
    }
  }
  &-structure {
    min-height: 1004px;
    background: #EFF4F5;
    padding-top: 65px;
    .desc {
      font-weight: 400;
      font-size: 26px;
      color: #151515;
      line-height: 37px;
      margin: 2px 0 60px;
    }
    &-items {
      text-align: center;
      img {
        width: 200px;
        height: 200px;
      }
      .title-img {
        font-weight: 500;
        font-size: 24px;
        color: #151515;
        line-height: 33px;
        margin-top: 23px;
      }
      .desc-img {
        font-weight: 400;
        font-size: 16px;
        color: #151515;
        line-height: 22px;
        margin-top: 17px;
      }
      .line-items {
        height: 1px;
        width: 100%;
        background: rgba(151, 151, 151, 0.4);
        margin-top: 51px;
      }
    }
    &-blocks {
      margin-top: 60px;
      .structure-block {
        height: 212px;
        background: #FFFFFF;
        border-radius: 10px;
        padding: 26px 0 0 17px;
        .stock-desc {
          font-weight: 400;
          font-size: 16px;
          color: #151515;
          line-height: 22px;
          margin: 23px 0 0 22px;
        }
        .stock-img {
          width: 244px;
        }
        .school-img {
          width: 277px;
        }
        .cool-img {
          width: 151px;
        }
      }
    }
  }
  &-vision {
    padding-bottom: 131px;
    .title {
      margin: 60px 0 50px;
    }
    .vision-left {
      width: 100%;
      height: 461px;
      background-image: url("../../assets/images/about/vision-left-bg.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      padding: 50px 0 0 30px;
      &-title {
        font-weight: 600;
        font-size: 32px;
        color: #FFFFFF;
        margin-bottom: 14px;
      }
      > .desc {
        width: 352px;
        font-weight: 400;
        font-size: 16px;
        color: #FFFFFF;
        line-height: 30px;
      }
    }
    .vision-right {
      width: 100%;
      height: 461px;
      background-image: url("../../assets/images/about/vision-right-bg.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      padding: 50px 0 0 30px;
      &-title {
        font-weight: 600;
        font-size: 32px;
        color: #151515;
        margin-bottom: 14px;
      }
      > .desc {
        width: 491px;
        font-weight: 600;
        font-size: 16px;
        color: #151515;
        line-height: 30px;
      }
    }

  }
  &-contrib {
    background: #EFF4F5;
    padding: 60px 0 46px;
    height: 768px;
    .title {
      margin-bottom: 43px;
    }
    .contrib-box {
      width: 200px;
      height: 557px;
      position: relative;
      font-weight: 600;
      font-size: 32px;
      color: #ddd;
      text-align: right;
      margin-right: 20px;
      .contrib-box-01 {
        margin-top: -6px;
        cursor: pointer;
        height: 38px;
        line-height: 38px;
      }
      .contrib-box-02 {
        margin-top: 48px;
        cursor: pointer;
        height: 38px;
        line-height: 38px;
      }
      .contrib-box-03 {
        margin-top: 58px;
        cursor: pointer;
        height: 38px;
        line-height: 38px;
      }
      .contrib-box-04 {
        margin-top: 76px;
        cursor: pointer;
        height: 38px;
        line-height: 38px;
      }
      .contrib-box-05 {
        margin-top: 60px;
        cursor: pointer;
        height: 38px;
        line-height: 38px;
      }
      .active {
        color: #C3012F;
        transition: all 0.6s ease;
      }
    }
    .contrib-img {
      width: 68px;
      height: 557px;
    }
    .contrib-block {
      width: 100%;
      height: 557px;
      background: #FFFFFF;
      padding: 67px 90px 0 70px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &-title {
        font-weight: 600;
        font-size: 36px;
        color: #C3012F;
      }
      &-desc {
        font-weight: 400;
        font-size: 16px;
        color: #151515;
        line-height: 37px;
        margin-top: 20px;
      }
      &-copy {
        font-weight: 400;
        font-size: 14px;
        color: #151515;
        line-height: 30px;
        margin-bottom: 54px;
      }
    }
  }
  &-awards {
    padding: 60px 0 56px;
    min-height: 858px;
    //min-height: 928px;
    position: relative;
    .title {
      margin-bottom: 44px;
    }
    .trophy-img {
      width: 527px;
      height: 441px;
      position: absolute;
      top: 0px;
      right: -142px;
    }
    .down-box {
      font-weight: 500;
      font-size: 14px;
      color: #515151;
      text-align: center;
      cursor: pointer;
      margin-top: 50px;
      .down-img {
        width: 50px;
        height: 50px;
        margin-bottom: 4px;
      }
      .up-img {
        //animation: rotateArrow 0.6s linear forwards;
        transform: rotate(180deg);
      }
    }
    .shallow-bg {
      background: linear-gradient( 90deg, #FFFFFF 0%, #EDEDED 49%, #FFFFFF 100%);
    }
    .shallow-block {
      width: 380px;
      height: 132px;
      background-image: url("../../assets/images/about/shallow.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      flex-direction: column;
      align-items: center;
      .shallow-line {
        width: 150px;
        height: 1px;
        border: 1px solid;
        border-image: linear-gradient(270deg, rgba(255, 255, 255, 0.29), rgba(190, 190, 190, 1), rgba(255, 255, 255, 0)) 1 1;
        margin: 13px 0 3px;
      }
      .shallow-desc {
        font-weight: 500;
        font-size: 14px;
        color: #846F4E;
        line-height: 32px;
      }
    }
    .awards-title {
      font-weight: 500;
      font-size: 18px;
      color: #151515;
      line-height: 28px;
      text-align: center;
      margin-top: 22px;
    }
    .deep-bg {
      background: linear-gradient( 90deg, #FFFFFF 0%, #F1E7D3 50%, #FFFFFF 100%);
    }
    .deep-block {
      width: 380px;
      height: 132px;
      background-image: url("../../assets/images/about/deep.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      flex-direction: column;
      align-items: center;
      .deep-line {
        width: 150px;
        height: 1px;
        border: 1px solid;
        border-image: linear-gradient(270deg, rgba(235, 222, 199, 0), rgba(196, 166, 119, 1), rgba(196, 166, 119, 0)) 1 1;
        margin: 13px 0 3px;
      }
      .deep-desc {
        font-weight: 500;
        font-size: 14px;
        color: #C30D23;
        line-height: 32px;
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .warp-about {
    .title {
      font-size: 34px;
    }
  }
}

@keyframes rotateArrow {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}