.wrap-scheme {
  background-color: #FFFFFF;
  .title {
    font-weight: 400;
    font-size: 32px;
    color: #151515;
    border-left: 10px solid #C3012F;
    padding-left: 19px;
    span {
      font-weight: 600;
    }
  }
  .wrap-title {
    font-weight: 600;
    font-size: 44px;
    color: #151515;
    line-height: 62px;
  }
  .desc {
    font-weight: 400;
    font-size: 16px;
    color: #151515;
    line-height: 28px;
    span {
      color: #C3012F;
      font-weight: 600;
    }
  }

  .wrap-bank-list {
    padding-bottom: 130px;
    &-header {
      background: #F8F8F8;
      padding: 61px 0 58px;
      &-box {
        display: flex;
        align-items: center;
      }
      img {
        width: 62px;
        height: 62px;
        margin-right: 5px;
      }
      .icon-note {
        width: 40px;
        height: 43px;
        margin-right: 21px;
      }
      .icon-frame {
        width: 47px;
        height: 50px;
        margin-right: 21px;
      }
    }
    &-summary {
      padding-top: 48px;
      border-bottom: 4px solid #CF112B;
      .desc {
        margin: 13px 10px 0 0;
      }
      .summary-data {
        height: 473px;
        background: #EFF4F5;
        border-radius: 20px;
        display: flex;
        justify-content: space-between;
        padding: 34px 65px 0;
        margin-top: 20px;
        &-block {
          img {
            width: 500px;
            height: 354px;
          }
          &-text {
            width: 462px;
            font-weight: 400;
            font-size: 14px;
            color: #515151;
            line-height: 20px;
            text-align: center;
            margin-top: 12px;
          }
        }

      }
      .summary-matrix {
        height: 529px;
        background: #EFF4F5;
        border-radius: 20px;
        margin: auto;
      }
      .wrap-drill-title {
        font-weight: 400;
        font-size: 44px;
        color: #CF112B;
        line-height: 62px;
        border-bottom: 8px solid #CF112B;
        display: inline-block;
        margin-top: 118px;
        span {
          font-weight: 600;
          border-radius: 20px;
        }
      }
    }
    &-drill {
      padding-top: 58px;
      background: linear-gradient( 180deg, #EFF4F5 0%, #FFFFFF 100%);
      .desc {
        margin: 16px 0 31px;
      }
      .block-drill {
        width: 100%;
        height: 100px;
        background: #FFFFFF;
        border-radius: 4px;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        &-title {
          font-weight: 600;
          font-size: 20px;
          color: #C3012F;
          margin-left: 50px;
          flex-shrink: 0;
        }
        &-line {
          width: 1px;
          height: 51px;
          background: #C3012F;
          //margin: 0 31px 0 42px;
          margin-right: 31px;
        }
        &-text {
          font-weight: 400;
          font-size: 16px;
          color: #151515;
          line-height: 22px;
          margin-right: 56px;
        }
      }
    }
    &-branch {
      margin-top: 65px;
      .branch-block {
        height: 473px;
        background: #EFF4F5;
        border-radius: 20px;
      }
    }
    .electric-data {
      height: 561px;
      width: 100%;
      background: #EFF4F5;
      border-radius: 20px;
      display: flex;
      margin-top: 20px;
      &-left {
        width: 297px;
        padding: 44px 34px 0 31px;
        font-weight: 400;
        font-size: 14px;
        color: #151515;
        line-height: 22px;
        .left-text-title {
          font-weight: 600;
          font-size: 20px;
          color: #151515;
          line-height: 28px;
          margin-bottom: 33px;
        }
        .left-text-desc-strong {
          font-weight: 600;
          font-size: 14px;
          color: #151515;
          line-height: 22px;
          margin-bottom: 12px;
        }
      }
      .electric-data-right {
        width: 890px;
        height: 532px;
        background: #FFFFFF;
        border-radius: 10px;
        margin-top: 15px;
        padding: 30px 32px 0 25px;
        .right-text-title {
          font-weight: 600;
          font-size: 20px;
          color: #151515;
          line-height: 28px;
          margin-bottom: 10px;
        }
        .right-text-block {
          height: 180px;
          background: #FFFFFF;
          border-radius: 10px;
          border: 2px solid #C30D23;
          padding: 16px 0 0 20px;
          position: relative;
          &-title {
            font-weight: 500;
            font-size: 18px;
            color: #C3012F;
            line-height: 25px;
            margin-bottom: 7px;
          }
          &-desc {
            display: flex;
            align-items: center;
            font-weight: 500;
            font-size: 14px;
            color: #151515;
            line-height: 22px;
            .dot {
              display: inline-block;
              width: 8px;
              height: 8px;
              background: #C3012F;
              flex-shrink: 0;
              margin-right: 8px;
            }
          }
          &-tips {
            height: 36px;
            background: #C3012F;
            border: 2px solid #C3012F;
            padding: 0 18px;
            position: absolute;
            right: 17px;
            top: -14px;
            font-size: 16px;
            color: #FFFFFF;
          }
          .down-arrows {
            width: 67px;
            height: 135px;
            position: absolute;
            right: 30px;
            bottom: -50px;
          }
        }
        .right-text-box {
          height: 196px;
          background: #C3012F;
          border-radius: 10px;
          border: 2px solid #C30D23;
          padding: 16px 15px 0 20px;
          font-weight: 500;
          font-size: 14px;
          color: #FFFFFF;
          line-height: 22px;
          &-title {
            font-weight: 500;
            font-size: 18px;
            color: #FFFFFF;
            line-height: 25px;
            margin-bottom: 7px;
          }
        }
      }
    }
    .electric-content {
      width: 100%;
      position: relative;
      font-weight: 400;
      font-size: 14px;
      color: #151515;
      line-height: 22px;
      span {
        font-weight: 600;
      }
      &-left {
        width: 242px;
        position: absolute;
        left: 40px;
        bottom: -10px;
      }
      &-right-t {
        width: 242px;
        position: absolute;
        right: 200px;
        top: 20px;
      }
      &-right-b {
        width: 283px;
        position: absolute;
        right: 0px;
        bottom: 12px;
      }
    }
  }
}