.wrap-home {

  .title {
    font-size: 44px;
  }
  .wrap-banner {
    height: calc(~"100vh - 90px");
    padding: 0 10px;
    display: flex;
    //overflow-x: hidden;
    &-left {
      margin-right: 10px;
      position: relative;
      &-box {
        position: absolute;
        bottom: 34px;
        color: #FFFFFF;
        width: 100%;
        .circle-box {
          margin-left: 30px;
          &-not {
            width: 31px;
            height: 16px;
            border-radius: 8px;
            border: 2px solid #FFFFFF;
            display: inline-block;
            margin-right: 10px;
            transition: width 0.3s ease;
            cursor: pointer;
          }
          &-active {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background: #FFFFFF;
            display: inline-block;
            transition: width 0.3s ease;
          }
        }
        .title {
          font-weight: 600;
          color: #FFFFFF;
          margin-left: 30px;
        }
        .block {
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: #FFFFFF;
          margin: 0 30px;
          &-left {
            font-weight: 400;
            font-size: 18px;
          }
          &-right {
            cursor: pointer;
            transition: box-shadow @animate-duration ease-in-out, transform @animate-duration ease-in-out;
            > img {
              width: 64px;
              height: 64px;
            }
          }
          &-right:hover {
            transform: translateY(-5px);
          }
        }
      }
      > img {
        width: 460px;
        height: 100%;
      }
    }
    &-right {
      width: calc(~"100% - 460px");
      //background-image: url("../../assets/images/bg01.png");
      //background-position: center;
      //background-repeat: no-repeat;
      //background-size: cover;
      //background: rgba(227, 241, 255, 0.64);
      //border-radius: 20px;
      //border: 2px solid #FFFFFF;
      position: relative;
      //padding: 45px 30px 30px;
      padding-right: 10px;
      //overflow: hidden;
      >.cube-img {
        height: 100%;
        position: absolute;
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        z-index: -10;
        animation: spin 20s linear infinite;
      }
      .wrap-banner-block {
        position: relative;
        width: 100%;
        height: calc(~"100vh - 90px");
        box-sizing: border-box;
        padding: 45px 0 30px 30px;
        background: rgba(227, 241, 255, 0.64);
        border-radius: 20px;
        border: 2px solid #FFFFFF;
        backdrop-filter: blur(3px);
        >.banner-bg {
          position: absolute;
          //top: 0px;
          //left: 0;
          //height: 100%;
          //width: 100%;
          top: 50%;
          left: 0;
          width: 100%;
          transform: translateY(-50%);
        }
        .title-box {
          position: absolute;
          cursor: pointer;
        }
        .title-box:hover {
          transition: 0.6s;
          color: #C3012F;
        }
      }

      .title {
        font-weight: 600;
        color: #151515;
      }

      .banner-box {
        display: flex;
        margin-bottom: 56px;
        &-left {
          margin-right: 42px;
          display: flex;
          flex-direction: column;
          align-items: baseline;
          max-width: 300px;
          min-width: 202px;
          .m-t {
            margin-top: 18px;
          }
          > .block {
            background: #C3012F;
            border-radius: 4px;
            border: 2px solid #C3012F;
            display: flex;
            align-items: center;
            height: 46px;
            padding: 0 17px 0 20px;
            margin-top: 10px;
            > span {
              font-size: 16px;
              color: #FFFFFF;
              margin-right: 10px;
            }
            > img {
              width: 20px;
              height: 20px;
            }
          }
        }
        &-right {
          margin-top: 58px;
          margin-right: 75px;
          .tactic-img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .wrap-banner-footer {
        position: absolute;
        bottom: 30px;
        width: 100%;
        padding: 0 30px;
        .banner-line {
          width: 100%;
          height: 2px;
          background: linear-gradient( 90deg, #C3012F 0%, rgba(195,1,47,0) 100%);
          margin-bottom: 20px;
        }
        .banner-footer {
          display: flex;
          > .active {
            background: #C3012F;
            color: #FFFFFF;
            transition: all 0.3s ease;
          }
          &-block {
            display: flex;
            color: #C3012F;
            //max-width: 210px;
            height: 64px;
            background: #FFFFFF;
            border-radius: 32px;
            align-items: center;
            margin-right: 10px;
            cursor: pointer;
            padding: 0 30px 0 22px;
            transition: all 0.3s ease;
            > .num {
              font-weight: 400;
              font-size: 36px;
              margin-right: 9px;
            }
            .box {
              &-top {
                font-size: 14px;
                font-weight: 600;
              }
              &-bottom {
                font-size: 12px;
                font-weight: 300;
              }
            }
          }
        }
      }
    }
  }
  .wrap-lecturer {
    margin-top: 123px;
    min-height: 709px;
    overflow: hidden;
    &-box {
      display: flex;
      justify-content: space-between;
      &-left {
        width: 558px;
        >.title {
          font-weight: 600;
          color: #151515;
          width: 558px;
          margin-bottom: 20px;
        }
        >.to-lecturer {
          width: 210px;
          height: 50px;
          background: #C3012F;
          border-radius: 32px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          cursor: pointer;
          transition: box-shadow @animate-duration ease-in-out, transform @animate-duration ease-in-out;
          > span {
            font-weight: 500;
            font-size: 16px;
            color: #FFFFFF;
            margin-right: 26px;
          }
          > img {
            width: 40px;
            height: 40px;
            margin-right: 5px;
          }
        }
        >.to-lecturer:hover {
          transform: translateY(-5px);
          box-shadow: 0 6px 16px fade(#000, 12)
        }
      }
      &-right {
        width: 599px;
        height: 200px;
        background: rgba(239, 244, 245, 0.44);
        border-radius: 20px;
        display: flex;
        justify-content: space-around;
        margin-top: 10px;
        .block {
          margin-top: 44px;
          &-title {
            font-size: 20px;
            color: #151515;
            width: 200px;
          }
          &-num {
            font-weight: 400;
            font-size: 50px;
            color: #C3012F;
          }
        }
      }
    }
    &-detail {
      //display: flex;
      margin-top: 55px;
      .case {
        width: 12px;
        height: 12px;
        border: 1px solid #C3012F;
        margin-right: 12px;
        cursor: pointer;
        &-active {
          background: #C3012F;
        }
      }
      .item-block {
        height: 330px;
        width: 390px;
        background: linear-gradient( 180deg, #EFF4F5 0%, #F9F9F9 100%);
        border-radius: 20px;
        position: relative;
        cursor: pointer;
        color: #515151;
        &-font {
          width: 132px;
          height: 100%;
          padding: 30px 0 20px 20px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          &-top {
            > .name {
              font-weight: 600;
              font-size: 24px;
            }
            > .desc {
              font-weight: 400;
              font-size: 14px;
              margin-top: 10px;
            }
          }
          > .l-img {
            width: 24px;
            height: 24px;
            background-image: url("../../assets/images/small_arrows.png");
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;

          }
        }
        &-person {
          width: 260px;
          //height: 336px;
          position: absolute;
          right: -4px;
          bottom: 0px;
          flex-shrink: 0;
        }
      }
      .item-block:hover {
        background-image: url("../../assets/images/lecturer_bg.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        color: #FFFFFF;
        animation: shake 0.5s;
        .item-block-font {
          > .l-img {
            width: 42px;
            height: 42px;
            background-image: url("../../assets/images/play.png");
          }
        }
      }
      .m-r {
        margin-right: 15px;
      }
    }
  }
  .wrap-consult {
    background: #EFF4F5;
    padding: 110px 0 140px;
    .title {
      font-weight: 600;
      color: #151515;
      margin-bottom: 40px;
    }
    &-wrap {
      display: flex;
      justify-content: space-between;
    }
    &-block {
      border-left: 4px solid silver;
      padding-left: 20px;
      margin-bottom: 30px;
      cursor: pointer;
      transition: all 0.8s ease;
      .consult-name {
        font-weight: 600;
        font-size: 13px;
        color: #C3012F;
      }
      .consult-title {
        font-weight: 500;
        font-size: 36px;
        line-height: 36px;
        color: #151515;
        margin-top: 6px;
      }
      .consult-desc {
        font-weight: 400;
        font-size: 20px;
        line-height: 21px;
        color: #515151;
        margin-top: 12px;
      }
      .arrows-img {
        width: 24px;
        height: 24px;
        margin-top: 24px;
      }
    }
    &-active {
      border-left: 4px solid #C3012F;
      transition: all 0.8s ease;
    }
    .img-c {
      width: 600px;
      height: 415px;
      border-radius: 10px;
      opacity: 1;
      margin-right: 12px;
    }
    .img-fade {
      transition: opacity 0.5s ease;
      animation: fadeOutIn 0.7s ease forwards;
    }
    .case {
      width: 12px;
      height: 12px;
      border: 1px solid #C3012F;
      margin-bottom: 12px;
      &-active {
        background: #C3012F;
      }
    }
  }
  .wrap-dynamic {
    min-height: 768px;
    overflow: hidden;
    padding-bottom: 76px;
    &-wrap {
      display: flex;
      justify-content: space-between;
      margin-top: 110px;
      margin-bottom: 60px;
      .title {
        font-weight: 600;
        color: #151515;
      }
      .direction {
        display: flex;
        .m-r {
          margin-right: 20px;
        }
        .l-img {
          background-image: url("../../assets/images/active_left.png");
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        }
        .r-img {
          background-image: url("../../assets/images/active_right.png");
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        }
        .l-img:hover {
          background-image: url("../../assets/images/hover_left.png");
        }
        .r-img:hover {
          background-image: url("../../assets/images/hover_right.png");
        }
        .img-font {
          width: 64px;
          height: 64px;
          cursor: pointer;
        }
      }
    }

    &-block {
      cursor: pointer;
      > .dynamic-date {
        font-weight: 600;
        font-size: 14px;
        color: #C3012F;
        line-height: 21px;
        margin-top: 20px;
        margin-right: 35px;
      }
      > .dynamic-title {
        font-weight: 500;
        font-size: 24px;
        color: #151515;
        line-height: 33px;
        margin-top: 10px;
        margin-right: 35px;
      }
      > .dynamic-desc {
        font-weight: 400;
        font-size: 14px;
        color: #515151;
        line-height: 21px;
        margin-top: 10px;
        margin-right: 35px;
      }
      > img {
        width: 390px;
        height: 300px;
        border-radius: 20px;
      }
      > img:hover {
        animation: shake 0.5s;
      }
    }
  }
  .wrap-teams {
    background: #EFF4F5;
    padding: 110px 0 74px;
    min-height: 768px;
    .title {
      font-weight: 600;
      color: #151515;
      text-align: center;
    }
    .content-wrapper {
      margin-top: 72px;
      .image-wrapper {
        width: 100%;
        height: 100px;
        .image-item {
          width: 240px;
          height: 100px;
          cursor: pointer;
          transition: box-shadow @animate-duration ease-in-out, transform @animate-duration ease-in-out;
        }
        .image-item:hover {
          transform: translateY(-5px);
          box-shadow: 0 6px 16px fade(#000, 12);
        }
      }
    }
  }
}
//@media screen and (min-width: 1300px) {
//  .wrap-home {
//    .wrap-banner {
//      &-right {
//        .wrap-banner-block {
//          > .banner-bg {
//            top: 70px;
//          }
//        }
//      }
//    }
//  }
//}
@media screen and (max-width: 1300px) {
  .wrap-home {
    .title {
      font-size: 34px;
      line-height: 42px;
    }
    .wrap-banner {
      &-left {
        &-box {
          .circle-box {
            &-one {
              width: 12px;
              height: 12px;
            }
            &-two {
              width: 27px;
              height: 12px;
            }
          }
          .block {
            &-left {
              font-size: 14px;
            }
            &-right {
              > img {
                width: 46px;
                height: 46px;
              }
            }
          }
        }
        > img {
          width: 390px;
          height: 100%;
        }
      }
      &-right {
        width: calc(~"100% - 390px");
        .wrap-banner-block {
          padding: 25px 20px 20px;
          > .banner-bg {
            width: 90%;
          }
        }
        .banner-box {
          margin-bottom: 12px;
          &-left {
            .m-t {
              margin-top: 12px;
            }

            > .block {
              height: 36px;
              padding: 0 13px 0 16px;
              margin-top: 6px;
              > span {
                font-size: 14px;
              }
              > img {
                width: 16px;
                height: 16px;
              }
            }
          }
          &-right {
            margin-top: 18px;
            margin-right: 126px;
            .tactic-img {
              width: 100%;
            }
          }
        }
        .wrap-banner-footer {
          bottom: 10px;
          padding: 0 20px;
          .banner-line {
            margin-bottom: 10px;
          }
          .banner-footer {
            &-block {
              padding: 0 20px 0 12px;
              .num {
                font-size: 32px;
                margin-right: 9px;
              }
            }
          }
        }
      }
    }
    .wrap-consult {
      &-block {
        .consult-title {
          font-size: 28px;
          line-height: 28px;
        }
        .consult-desc {
          font-size: 18px;
          line-height: 18px;
        }
      }
    }
  }
}

@keyframes fadeOutIn {
  0% { opacity: 0.6; }
  50% { opacity: 0.7; }
  70% { opacity: 0.8; }
  90% { opacity: 0.9; }
  100% { opacity: 1; }
}

@keyframes spin {
  0% {
    transform: translateX(0) scale(1);
  }
  50% {
    transform: translateX(100px) scale(1.2);
  }
  100% {
    transform: translateX(0) scale(1);
  }
}

@keyframes shake {
  0% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  50% { transform: translateX(5px); }
  75% { transform: translateX(-3px); }
  100% { transform: translateX(0); }
}